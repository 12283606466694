<template>
  <dialog
    id="global-modal"
    ref="modal"
    class="fr-modal"
    role="dialog"
    aria-labelledby="global-modal-title"
  >
    <div class="fr-container fr-container--fluid fr-container-md">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-col-12 fr-col-md-8 fr-col-lg-6">
          <div class="fr-modal__body">
            <div class="fr-modal__header">
              <button
                v-autofocus
                class="fr-btn--close fr-btn"
                aria-controls="global-modal"
                title="Fermer"
                @click="close"
              >
                Fermer
              </button>
            </div>
            <div class="fr-modal__content">
              <h1
                v-if="title"
                id="global-modal-title"
                class="fr-modal__title"
              >
                <span
                  class="fr-icon-arrow-right-line fr-icon--lg"
                  aria-hidden="true"
                ></span>
                {{ title }}
              </h1>
              <component
                :is="modalState.component"
                v-if="modalState"
                v-bind="modalState.props"
                v-model="isOpen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts" setup generic="M extends Component">
  import { inject } from 'vue'
  import { useModal, modalInjectionKey } from '../../composables/useModal'

  const modalState = inject(modalInjectionKey)

  const modal = ref<HTMLDialogElement | null>(null)

  const { isOpen, setReady, setIsOpen, title, triggerOpen, close } = useModal()

  // This becomes non null much later in the page loading process
  // and prevents the modal to be disclosed when using triggerOpen
  const dsfrModal = computed(() => {
    return modal.value ? window.dsfr(modal.value) : undefined
  })

  const unwatch = watch(modal, () => {
    // This is the hack that allows the modal to be set ready when
    // (I guess) window.dsfr becomes non null
    if (modal.value) {
      setTimeout(() => {
        setReady()
        unwatch()
      }, 1000)
    }
  })

  const stopWatch = watch(isOpen, value => {
    if (!value) {
      dsfrModal.value?.modal.conceal()
    }
    if (value && triggerOpen.value) {
      dsfrModal.value?.modal.disclose()
    }
  })

  function onClose() {
    setIsOpen(false)
    close()
  }
  // Modal closes and destroy component when concealed
  onMounted(() => {
    modal.value?.addEventListener('dsfr.conceal', onClose)
  })

  onUnmounted(() => {
    stopWatch()
    modal.value?.removeEventListener('dsfr.conceal', onClose)
  })
</script>
